import { AxiosResponse, AxiosService } from '@/plugins/axios'
import { AxiosRequestConfig } from 'axios'
import { PayloadListType } from '@/types/general'
import { ResponseDetailTaskAnswerType, TaskAnswersType } from '@/types/tasks'

export default class TaskAnswerApi {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/taskanswer'
          : '/api/taskanswer',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async taskAnswerCreate(data: unknown): Promise<AxiosResponse<unknown>> {
    return await this.axiosCall<unknown>({
      method: 'post',
      url: '/create',
      data,
    })
  }

  async getTaskAnswers(
    params?: PayloadListType
  ): Promise<AxiosResponse<TaskAnswersType>> {
    return await this.axiosCall<TaskAnswersType>({
      method: 'get',
      url: `/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async getTaskAnswer(
    id: string
  ): Promise<AxiosResponse<ResponseDetailTaskAnswerType | []>> {
    return await this.axiosCall<ResponseDetailTaskAnswerType | []>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }
}

export const taskAnswerAPI = new TaskAnswerApi()
