
































import { Component, Vue, Model } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import TextAreaCommon from '@/components/common/TextAreaCommon.vue'

@Component({
  components: {
    InputCommon,
    DatePickerCommon,
    TextAreaCommon,
  },
})
export default class ReturnTaskForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  returnTaskForm = {
    completeBy: '',
    comment: '',
  }

  handleCloseDialog(): void {
    this.$emit('change')
  }

  handleReturnTask(): void {
    this.$emit('change')
  }
}
