


















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { TableOrderType } from '@/types/general'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'

@Component({
  components: { TableColumnHeader },
})
export default class TasksTable extends Vue {
  @Prop({ type: Array })
  tasks!: TableOrderType[]

  @Prop({ type: Boolean })
  loading?: boolean

  taskStatus = {
    open: 'Новая',
    active: 'Активна',
    closed: 'Закрыта',
  }

  taskColor = {
    open: 'default',
    active: 'success',
    closed: 'danger',
  }

  setTaskStatusColor(status: string): string {
    return status === 'overdue' ? 'color-gray' : 'color-dark'
  }

  handleAddFilter(filter: { data: string; target: string }): void {
    this.$emit('add-filter', { data: filter.data, target: filter.target })
  }

  handleShowTaskInfo(row: { id: number; UF_REMOTE_USER_ID: number }, e): void {
    if (e.property === 'UF_USER_LOGIN' && row.UF_REMOTE_USER_ID > 1) {
      this.$emit('show-member-info', row.UF_REMOTE_USER_ID)
    } else {
      this.$emit('show-task-info', row.id)
    }
  }

  handleChangeSort(data: TableOrderType): void {
    this.$emit('change-sort', data)
  }
}
