























































































































































































































import {
  Component,
  Vue,
  Model,
  Prop,
  Watch,
  Emit,
} from 'vue-property-decorator'
import Drawer from '@/components/common/Drawer.vue'
import { Message, Upload } from 'element-ui'
import InputCommon from '@/components/common/InputCommon.vue'
import TextAreaCommon from '@/components/common/TextAreaCommon.vue'
import {
  CommentsType,
  ResponseDetailTaskAnswerType,
  TaskAnswersType,
  TasksItemListType,
} from '@/types/tasks'
import { taskAnswerAPI } from '@/api/taskanswer.api'
import { FileType } from '@/types/commmon.types'
import { tasksAPI } from '@/api/tasks.api'
import { TASK_FILE_ACCEPT } from '@/constants'

@Component({
  methods: {
    TASK_FILE_ACCEPT() {
      return TASK_FILE_ACCEPT
    },
  },
  components: {
    TextAreaCommon,
    InputCommon,
    Drawer,
  },
})
export default class TaskInfo extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  @Prop({ type: Object })
  taskInfo!: TasksItemListType

  @Prop({ type: Object })
  taskAnswer!: ResponseDetailTaskAnswerType

  @Prop({ type: Boolean })
  loading?: boolean

  @Prop({ type: Boolean })
  isAnimationEnd!: boolean

  @Prop({ type: Object })
  taskAnswers?: TaskAnswersType

  @Prop({ type: Boolean })
  showAllComments!: boolean

  @Emit('show-more-comments')
  handleShowMoreComments(): string {
    return this.taskInfo.ID
  }

  @Watch('taskAnswers')
  setTaskAnswers(): void {
    this.setAnswers()
  }

  @Watch('loading')
  clearFiles(): void {
    if (this.loading) {
      this.answers = {}
      this.comment = ''
      this.files = []
      this.commentVisible = false
    } else {
      this.setAnswers()
    }
  }

  $refs!: {
    upload: Upload
    text: TextAreaCommon
  }

  taskStatus = {
    open: 'Новая',
    active: 'Активная',
    closed: 'Закрыта',
  }

  taskColor = {
    open: 'default',
    active: 'success',
    closed: 'danger',
  }

  comments: CommentsType[] = [
    {
      file: null,
      title: '',
    },
  ]

  answers = {} as Partial<TaskAnswersType>

  comment = ''

  files: FileType[] | null = null

  commentVisible = false

  showMemberInfo(): void {
    if (+this.taskInfo.UF_REMOTE_USER_ID > 1) {
      this.$emit('show-member-info', this.taskInfo.UF_REMOTE_USER_ID)
    }
  }

  setAnswers(): void {
    if (this.taskAnswers) {
      this.answers = this.taskAnswers
    }
  }

  handlePhotoUpload(file: FileType): void {
    if (file && this.files) {
      this.files.push(file)
    }
  }

  handleClickFileDelete(index: number): void {
    if (this.files) {
      this.files.splice(index, 1)
    }
  }

  handleCloseTaskInfo(): void {
    this.$emit('change')
  }

  cutExtension(fileName: string): string {
    return fileName.split('.').slice(0, -1).join('.')
  }

  handleShopInfoOpen(): void {
    this.$emit('show-shop-info', this.taskInfo.UF_REMOTE_SHOP_ID)
  }

  async handleClickDownloadFile(id: number): Promise<void> {
    const link = document.createElement('a')
    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + `/api/file/downloadId/${id}`
    )
    link.setAttribute('download', 'download')
    link.click()
    link.remove()
  }

  handleCommentAddSetVisible(): void {
    this.commentVisible = true

    this.$nextTick(() => {
      const textArea = document.querySelector(
        '.task-info__content .dc-textarea.textarea-common__input'
      ) as HTMLInputElement

      textArea?.focus()
    })
  }

  handleShowReturnTask(): void {
    this.$emit('return-task')
  }

  //TODO Копировать
  async handleCommentAdd(): Promise<void> {
    this.commentVisible = true

    const formData = new FormData()

    formData.append('UF_ANSWER', this.comment)
    formData.append('UF_TASK_ID', this.taskInfo.id)

    if (this.files) {
      this.files.forEach((file) => {
        if (file && file.raw) {
          formData.append('UF_NAME_FILES[]', file.raw)
        }
      })
    }

    if (this.comment === '') {
      Message({
        type: 'error',
        message: 'Заполните ответ',
      })
    } else {
      const [error, data] = await taskAnswerAPI.taskAnswerCreate(formData)

      if (!error && data) {
        this.handleCloseTaskInfo()

        await this.$store.dispatch('notifications/getNotificationsCount')
      }
    }
  }

  async handleTaskActive(): Promise<void> {
    const [error, data] = await tasksAPI.activeTask(this.taskInfo.id)

    if (!error && data) {
      this.handleCloseTaskInfo()

      await this.$store.dispatch('notifications/getNotificationsCount')
    }
  }

  async handleTaskClose(): Promise<void> {
    const [error, data] = await tasksAPI.closeTask(this.taskInfo.id)

    if (!error && data) {
      this.handleCloseTaskInfo()

      await this.$store.dispatch('notifications/getNotificationsCount')
    }
  }
}
