var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"tasks-table",attrs:{"data":_vm.tasks},on:{"row-click":_vm.handleShowTaskInfo,"sort-change":_vm.handleChangeSort}},[_c('el-table-column',{attrs:{"sortable":"custom","prop":"ID","label":"Id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.setTaskStatusColor(row.UF_STATUS)},[_vm._v(" "+_vm._s(row.id)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"UF_STATUS","label":"Статус"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('dc-tag',{staticClass:"status-tag",attrs:{"mode":_vm.taskColor[row.UF_STATUS],"clickable":"","size":"sm"}},[_c('span',[_vm._v(_vm._s(_vm.taskStatus[row.UF_STATUS]))])])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"UF_MESSAGE","label":"Описание"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"tasks-table__description",class:_vm.setTaskStatusColor(row.UF_STATUS)},[_vm._v(" "+_vm._s(row.UF_MESSAGE)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"UF_DATE_INSERT","label":"Дата создания"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.setTaskStatusColor(row.UF_STATUS)},[_vm._v(" "+_vm._s(row.UF_DATE_INSERT)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"UF_DATE_UPDATE","label":"Дата завершения"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.setTaskStatusColor(row.UF_STATUS)},[_vm._v(" "+_vm._s(row.UF_DATE_UPDATE)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"UF_USER_LOGIN","label":"Ответственный"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"link"},[_vm._v(" "+_vm._s(row.UF_USER_LOGIN)+" ")])]}},{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"UF_USER_LOGIN","label":"Ответственный","inputLabel":"Ответственный"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }