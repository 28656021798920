























import { Component, Vue, Prop } from 'vue-property-decorator'
import { TasksItemListType } from '@/types/tasks'

@Component
export default class TasksCard extends Vue {
  @Prop({ type: Object })
  task!: TasksItemListType

  taskStatus = {
    open: 'Новая',
    active: 'Активная',
    closed: 'Закрыта',
  }

  taskColor = {
    open: 'default',
    active: 'success',
    closed: 'danger',
  }

  handleShowTaskDetail(): void {
    this.$emit('show-task', this.task.id)
  }
}
