











































































































































import { Component, Vue, Model } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { Upload, Form } from 'element-ui'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import SelectCommon from '@/components/common/SelectCommon.vue'
import TextAreaCommon from '@/components/common/TextAreaCommon.vue'
import {
  AddTaskFormType,
  FileType,
  ResponseDetailedMemberType,
} from '@/types/tasks'
import { shopsAPI } from '@/api/shops.api'
import { membersAPI } from '@/api/members.api'
import { SelectType } from '@/types/promotions'
import { ShopsListItemType } from '@/types/shops'
import { TASK_FILE_ACCEPT } from '@/constants'

@Component({
  methods: {
    TASK_FILE_ACCEPT() {
      return TASK_FILE_ACCEPT
    },
  },
  components: {
    DatePickerCommon,
    SelectCommon,
    InputCommon,
    TextAreaCommon,
  },
})
export default class AddTaskForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  showSelect = false

  $refs!: {
    upload: Upload
    form: Form
  }

  addTaskForm: AddTaskFormType = {
    dateStart: '',
    dateEnd: '',
    shop: '',
    responsible: '',
    description: '',
    files: [],
    comment: '',
  }

  isLoading = false

  params = {
    filter: {
      UF_NAME: '',
    },
    order: {},
    offset: 0,
  }

  shops: SelectType[] = []
  members: SelectType[] = []
  membersDetailed = {} as Partial<ResponseDetailedMemberType>
  foundShop = {} as Partial<ShopsListItemType>

  rules = {
    shop: [
      {
        required: true,
        message: 'Поле Магазин обязательно для заполнения',
        trigger: 'blur',
      },
    ],
  }

  get uploadedPhotos(): string[] {
    return this.$store.getters['files/uploadedFiles']
  }

  get showMembersSelect(): boolean {
    return this.addTaskForm.shop !== ''
  }
  get shopsData(): ShopsListItemType[] {
    return this.$store.getters['shops/shopsList']
  }

  deleteFile(index: number): void {
    this.addTaskForm.files.splice(index, 1)
  }

  handlePhotoUpload(file: FileType): void {
    this.addTaskForm.files.push(file)
    this.$store.dispatch('files/uploadFiles', file.raw)
  }

  handleCloseDialog(): void {
    this.$emit('change')
  }

  handleAddTask(): void {
    const params = {
      UF_TYPE: 'task',
      UF_STATUS: 'open',
      UF_USER_SHOP: this.foundShop.UF_NAME,
      UF_MESSAGE: this.addTaskForm.description,
      'UF_NAME_FILES[]': JSON.stringify(this.uploadedPhotos),
      UF_USER_LOGIN: this.addTaskForm.responsible,
      UF_NOTES: this.addTaskForm.comment,
      UF_REMOTE_USER_ID: this.membersDetailed.ID,
      UF_REMOTE_SHOP_ID: this.foundShop.id,
    }

    const formData = new FormData()

    this.addTaskForm.files.forEach((file) => {
      formData.append('UF_NAME_FILES[]', file.raw)
    })

    Object.entries(params).forEach(([key, value]) => {
      formData.append(key, value as string | Blob)
    })

    this.$refs.form.validate(async (valid) => {
      if (valid) {
        this.isLoading = true

        await this.$store.dispatch('tasks/createTask', formData)

        this.isLoading = false

        this.$emit('close-form')

        this.shops = []
        this.members = []
        this.membersDetailed = {}
        this.foundShop = {}

        this.$refs.form.resetFields()

        await this.$store.dispatch('notifications/getNotificationsCount')
      } else {
        return false
      }
    })
  }

  cutExtension(fileName: string): string {
    return fileName.split('.').slice(0, -1).join('.')
  }

  async handleMemberGet(id: string): Promise<void> {
    const [error, data] = await membersAPI.getMember(id)

    if (!error && data && data.items.length) {
      this.membersDetailed = data.items[0]
    }
  }

  async handleShopsSearch(value: string): Promise<void> {
    if (value.length < 2) {
      this.showSelect = false
      return
    } else {
      this.showSelect = true
    }

    // const [error, data] = await shopsAPI.getShops(value)
    // console.log(value)

    const params = {
      filter: {
        UF_NAME: value,
      },
    }
    // const [error, data] = this.shopsData

    await this.$store.dispatch('shops/getShopsList', {
      params: params,
    })

    const error = null
    const data: SelectType[] = []
    this.shopsData.forEach((item) => {
      const label =
        item.UF_NAME +
        ', ' +
        item.UF_COUNTRY +
        ', ' +
        item.UF_REGION +
        ', ' +
        item.UF_STREET +
        ', ' +
        item.UF_INDEX
      const value = item.UF_REMOTE_ID as string
      const id = item.UF_CODE as string

      data.push({ label: label, value: value, id: id })
    })

    if (!error && data) {
      this.shops = data
    }
  }

  async getDetailedShop(id: string): Promise<void> {
    const [error, data] = await shopsAPI.shopsDetail(+id)

    if (!error && data) {
      data.REMOTE_USERS.items.forEach((user) => {
        this.members.push({
          label: user.UF_REMOTE_USER_LOGIN,
          value: user.UF_REMOTE_USER_LOGIN,
        })
      })

      this.foundShop = data
    }
  }

  async handleShopSearch(value: string): Promise<void> {
    const currentShop = this.shops.find((shop) => shop.value === value)

    if (currentShop) {
      const [error, data] = await shopsAPI.getShop(currentShop.id as string)

      if (!error && data) {
        await this.getDetailedShop(data.items[0].ID)
      }
    }
  }
}
