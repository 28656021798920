export const TASK_FILE_ACCEPT = [
  '.doc',
  '.docx',
  '.xlc',
  '.xlsx',
  '.csv',
  '.pdf',
  '.jpeg',
  '.jpg',
] as const
